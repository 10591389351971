<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12" id="form">
        <b-overlay :show="loading">
          <iq-card>
            <template v-slot:body>
              <ValidationObserver ref="searchs"  v-slot="{ handleSubmit }">
                <b-form  @submit.prevent="handleSubmit(loadData)" >
                  <b-row>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="File No" vid="file_no" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="file_no"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('li_step.bin_no')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="file_no"
                              v-model="formData.file_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="error invalid-feedback" role="alert">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <div class="col">
                      <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                    </div>
                  </b-row>
                </b-form>
              </ValidationObserver>
              <hr/>
              <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                <b-form id="forms" @submit.prevent="handleSubmit(register)" >
                  <b-row>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Company Name En" vid="org_name_en" rules="required">
                        <b-form-group
                            label-for="org_name"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('foreignCompany.organization_name')}} ({{$t('globalTrans.en')}})<span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="org_name"
                              v-model="formData.org_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Company Name Bn" vid="org_name_bn" rules="required">
                        <b-form-group
                            label-for="org_name_bn"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('foreignCompany.organization_name')}} ({{$t('globalTrans.bn')}})<span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="org_name_bn"
                              v-model="formData.org_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Company Address En" vid="organization_address_en" rules="required">
                        <b-form-group
                            label-for="organization_address"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('boro_import.company_address')}} ({{$t('globalTrans.en')}})<span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="org_address"
                              v-model="formData.org_address"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                     <b-col lg="6" sm="12">
                      <ValidationProvider name="Company Address Bn" vid="org_address_bn" rules="required">
                        <b-form-group
                            label-for="organization_address"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('boro_import.company_address')}} ({{$t('globalTrans.bn')}})<span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="org_address_bn"
                              v-model="formData.org_address_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row v-if="formData.all_application.length > 0">
                    <div class="col-md-12">
                      <fieldset class="p-2 w-100">
                        <legend class="px-2 w-50 shadow-sm">{{$t('globalTrans.application_information')}}</legend>
                        <table class="table" style="padding-left: 15px;padding-right: 15px">
                          <thead class="thead">
                              <tr>
                                <th style="width:10%">{{$t('globalTrans.sl_no')}}</th>
                                <th style="width:15%">{{$t('service_name.service_name')}}</th>
                                <th style="width:15%">{{$t('li_step.application_id')}}</th>
                                <th style="width:15%">{{$t('li_step.license_no')}}</th>
                                <th style="width:15%">{{$t('externalLrcpn.type')}}</th>
                                <th style="width:15%">{{$t('externalLrcpn.issue_date')}}</th>
                                <th style="width:15%">{{$t('externalLrcpn.expired_date')}}</th>
                              </tr>
                              <tr v-for="(application, index) in formData.all_application" :key="index">
                                <td>{{ $n(index + 1) }}</td>
                                <td>{{ getColumnName($store.state.licenseRegistration.commonObj.serviceNamesList, application.service_id) }}</td>
                                <td>{{ application.application_id }}</td>
                                <td>
                                  <slot v-if="application.service_id === 5">IMP_</slot>
                                  <slot v-else-if="application.service_id === 6"> M_</slot>
                                  <slot v-else-if="application.service_id === 23">S_</slot>
                                  {{ application.generate_id }}
                                </td>
                                <td>
                                  <span class="badge pay-status badge-success" v-if="application.type === 1">
                                    {{ $t('globalTrans.new') }}
                                  </span>
                                  <span class="badge pay-status badge-info" v-else>
                                    {{ $t('globalTrans.renew') }}
                                  </span>
                                </td>
                                <td>{{ application.issue_date | dateFormat }}</td>
                                <td>{{ application.expire_date | dateFormat }}</td>
                            </tr>
                          </thead>
                        </table>
                      </fieldset>
                    </div>
                  </b-row>
                  <b-row>
                    <div class="col-md-12">
                      <fieldset class="p-2 w-100">
                        <legend class="px-2 w-50 shadow-sm">{{$t('component_settings.history_attachment')}}</legend>
                        <table class="table" style="padding-left: 15px;padding-right: 15px">
                          <thead class="thead">
                            <tr>
                              <th style="width:30%">{{$t('component_settings.history_attachment')}} ({{$t('globalTrans.en')}})<span class="text-danger">*</span></th>
                              <th style="width:30%">{{$t('component_settings.history_attachment')}} ({{$t('globalTrans.bn')}})<span class="text-danger">*</span></th>
                              <th style="width:30%">{{$t('globalTrans.attachment')}} </th>
                              <th style="width:10%"></th>
                            </tr>
                          </thead>
                          <tr v-for="(company_attachment, index) in formData.company_attachments" :key="index">
                            <td>
                              <keep-alive>
                                <ValidationProvider :vid="'company_attachment' + index" rules="" name="Company Attachment (En)">
                                  <div slot-scope="{ valid, errors }">
                                    <b-form-input
                                      rows="2"
                                      v-model="company_attachment.company_attachment"
                                      :name="'company_attachments['+index+'][company_attachment]'"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="error invalid-feedback" role="alert">
                                      {{ errors[0] }}
                                    </div>
                                  </div>
                                </ValidationProvider>
                              </keep-alive>
                            </td>
                            <td>
                              <keep-alive>
                                <ValidationProvider :vid="'company_attachment_bn' + index" rules="required"  name="Company Attachment (Bn)">
                                  <div slot-scope="{ valid, errors }">
                                    <b-form-input
                                      rows="2"
                                      v-model="company_attachment.company_attachment_bn"
                                      :name="'company_attachments['+index+'][company_attachment_bn]'"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="error invalid-feedback" role="alert">
                                      {{ errors[0] }}
                                    </div>
                                  </div>
                                </ValidationProvider>
                              </keep-alive>
                            </td>
                            <td>
                              <input type="hidden" :name="'company_attachments['+index+'][id]'" v-model="company_attachment.id"/>
                              <input type="hidden" :name="'company_attachments['+index+'][pre_attachment]'" v-model="company_attachment.attachment"/>
                              <ValidationProvider :vid="'company_attachments.' + index + '.attachment'" name="Attachment">
                                  <div slot-scope="{ valid, errors }">
                                    <b-form-file
                                      :name="'company_attachments['+index+'][attachment]'"
                                      :data-id="company_attachment.id"
                                       accept=".jpg, .doc, .docx, .zip, .pdf"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-file>
                                    <div class="error d-block invalid-feedback" role="alert">
                                      {{ errors[0] }}
                                    </div>
                                  </div>
                                </ValidationProvider>
                                <b v-if="company_attachment.pre_attachment">
                                  (<a target = '_blank' :href="licenseRegistrationServiceBaseUrl + 'storage/' + company_attachment.pre_attachment" download>{{$t('globalTrans.view_download')}}</a> )
                                </b>
                            </td>
                            <td>
                              <button v-if="index != 0" @click="removeAttachment(index, company_attachment)" class="btn btn-sm btn-danger float-right" type="button">
                                <i class="fas fa-window-close m-0"></i>
                              </button>
                            </td>
                          </tr>
                          <template v-if="formData.company_attachments.length === 0">
                            <tr>
                              <th colspan="5" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                            </tr>
                          </template>
                          <tr>
                            <td colspan="5">
                              <button
                                @click="addAll"
                                class="btn btn-sm btn-primary float-right"
                                type="button"
                              >{{$t('elearning_tpm.add_in_list')}}</button>
                            </td>
                          </tr>
                        </table>
                      </fieldset>
                    </div>
                  </b-row>
                  <div class="row">
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </template>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { daeBinCompanyAttachmentStore, daeBinCompanyAttachmentUpdate, daeBinCompanyAttachment } from '../../api/routes'
import commonAuth from '../CommonAuth'

export default {
  props: ['id'],
  mixins: [commonAuth],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        service_id: 0,
        application_id: '',
        file_no: '',
        org_name: '',
        org_name_bn: '',
        org_address: '',
        org_address_bn: '',
        company_attachment_remove: [],
        all_application: [],
        company_attachments: [
          {
            name: '',
            name_bn: '',
            attachement: ''
          }
        ]
      },
      loading: false,
      allApplications: [],
      licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
      serviceList: []
    }
  },
  created () {
    this.formData.org_id = this.userCheck()
    if (this.id) {
      const tmp = this.getformData()
      const atData = tmp.company_attachments.map(item => {
        const fileData = {}
        fileData.pre_attachment = item.attachment
        return Object.assign({}, item, fileData)
      })
      tmp.company_attachments = atData
      tmp.company_attachment_remove = []
      this.formData = tmp
    }
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  },
  watch: {
    'formData.org_id': function (newVal) {
      this.serviceList = this.getServiceList(newVal)
    }
  },
  methods: {
    getColumnName (list, groupId, typeIt = null) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (typeIt) {
          return obj.text_bn
        }
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    removeAttachment (index, data) {
      this.formData.company_attachments.splice(index, 1)
      this.formData.company_attachment_remove.push(data)
    },
    addAll () {
      this.formData.company_attachments.push({})
    },
    getServiceList (orgId) {
      return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.status === 1 && item.org_id === orgId)
    },
    getformData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async loadData () {
      const params = Object.assign({}, { file_no: this.formData.file_no, id: this.formData.id })
      this.loading = true
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, daeBinCompanyAttachment, params)
      if (result.success) {
        this.formData.service_id = result.data.service_id
        this.formData.application_id = result.data.application_id
        this.formData.org_name_bn = result.data.org_name_bn
        this.formData.org_name = result.data.org_name
        this.formData.org_address = result.data.org_address
        this.formData.org_address_bn = result.data.org_address_bn
        this.formData.all_application = result.allApplications
      } else {
        this.loading = false
        this.$refs.searchs.setErrors(result.errors)
        this.formData.service_id = 0
        this.formData.application_id = ''
        this.formData.org_name_bn = ''
        this.formData.org_name = ''
        this.formData.org_address = ''
        this.formData.org_address_bn = ''
      }
      this.loading = false
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      this.loading = true
      var formData = new FormData(document.getElementById('forms'))
      Object.keys(this.formData).map(key => {
        if (key !== 'company_attachments') {
          formData.append(key, this.formData[key])
        }
      })
      formData.append('company_attachment_remove', JSON.stringify(this.formData.company_attachment_remove))

      if (this.formData.id) {
        formData.append('_id', this.formData.id)
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, `${daeBinCompanyAttachmentUpdate}/${this.id}`, formData, config)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, daeBinCompanyAttachmentStore, formData, config)
      }
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.loading = false
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
